<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>Scatter Metrics</h3>
                </v-col>
                <v-col cols="12" md="6"> <selector-metric label="Horizontal Axis Metric" @selected="e=>{request_query.xaxis_metric = e}"></selector-metric> </v-col>
                <v-col cols="12" md="6"> <selector-metric  label="Vertical Axis Metric" @selected="e=>{request_query.yaxis_metric = e}"></selector-metric> </v-col>

                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small light block @click="do_get_pair_metrics_scatter_data()" :disabled="!( request_query.xaxis_metric && request_query.yaxis_metric)"> Get Data for {{pairs.length}} Pairs & Plot</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="data">
                <v-col sm="2" md="2">
                    <div style="display:flex; justify-content:center; flex-direction:column; height:100%;" >

                        <v-btn-toggle shaped class="vertical-toggle" >
                            <v-btn v-for="m in request_query.yaxis_metric.args_eval" :key="m.name" @click="selected.yaxis = m.name"> {{m.name}}</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col sm="10" md="10" v-if="selected.xaxis  && selected.yaxis">
                    <!-- <span>SELECTED : {{selected}}</span> -->
                    <plotly-2d-scatter 
                    :xData="data.plotly_2d_scatter.x_data.map(x=> x[selected.xaxis])" 
                    :yData="data.plotly_2d_scatter.y_data.map(x=> x[selected.yaxis])" 
                    :chartName="`${selected.xaxis} x ${selected.yaxis}`"
                    :metaData="{xAxisName:selected.xaxis  , yAxisName:selected.yaxis}"
                    >
                    </plotly-2d-scatter>
                    
                </v-col>
                <v-col sm="12" md="12" v-if="request_query.xaxis_metric">
                    <div style="display:flex; justify-content:center">
                        <v-btn-toggle shaped  >
                            <v-btn 
                            v-for="m in request_query.xaxis_metric.args_eval" 
                            :key="m.name" 
                            @click="selected.xaxis = m.name"> {{m.name}}</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="data">
        </div>
        <!-- <pre> {{selected}} {{data}} </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import Plotly2dScatter from './plotly/plotly-2d-scatter.vue'
import SelectorMetric from './selector-pair-metric.vue'
export default {
    name: 'intel-token-metrics-scatter',
    components:{
        SelectorMetric,
        Plotly2dScatter   
    },
    emits:[
 
    ],
    data(){return {
        request_query:{
            xaxis_metric:undefined,
            yaxis_metric:undefined,

        },
        selected:{
            xaxis:undefined,
            yaxis:undefined,
        },
        data:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        pairs:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['pair_metrics_scatter_data']),
        do_get_pair_metrics_scatter_data(){
            const  pair_id_list = this.pairs.map(x=>x.id)
            this.pair_metrics_scatter_data({qs:{},data:{metrics:[this.request_query.xaxis_metric,this.request_query.yaxis_metric], pair_ids:pair_id_list}}).then(
                r=>{
                    this.data = r.data
                }
            )
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.vertical-toggle {
  flex-direction: column;
}
</style>