<template>
    <div>

        <v-container fluid v-if="info" >
            <v-row>
                <v-col sm="12" md="12" >
                    <h3>
                        {{info.name}}
                        <sub> 
                            [ id {{listId}} ] 
                            <v-btn class="ma-0"  elevation="2" x-small fab @click="reload_listings" >
                                <v-icon small class="ma-0">mdi-refresh</v-icon>
                            </v-btn>
                        </sub>
                    </h3>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small  @click="dialog.args_spec = true" >
                        <v-icon class="ma-0">mdi-clock</v-icon>
                    </v-btn>
                    <v-btn v-if="info" class="ma-0"  elevation="2" block  color="pink" @click="dialog.input_pair_streams= !dialog.input_pair_streams" >
                        {{info.input_stream_fnc_name.replaceAll('_',' ')}}
                        
                    
                     
                    </v-btn>
                    <!-- <pre> {{info}} </pre> -->

                    <intel-pair-list-dynamic-conditions :pair_dynamic_list_id="listId" @refresh="reload_listings"></intel-pair-list-dynamic-conditions>
                </v-col>

                <v-col cols="12" md="12" v-if="disp_list_id != 0" >
                    <intel-pair-list :list_id="disp_list_id" :list_type="'tokens.dynamic'" :dl_args_spec="prop_args_spec"></intel-pair-list>
                </v-col>
                <div style="display:flex;justify-content:center" v-else>
                    <v-progress-circular indeterminate color="white" ></v-progress-circular>
                </div>

            </v-row>
        </v-container>
    
    <v-dialog v-model="dialog.input_pair_streams"  max-width="70vw" >
        <v-sheet rounded="sm" v-if="info">
            <app-pair-input-streams @saved="dialog.input_pair_streams =false; reload_listings()" :dl_id="info.id" :saved_input_stream="{args: info.input_stream_args_now , name:info.input_stream_fnc_name}"></app-pair-input-streams>
        </v-sheet>
    </v-dialog>

    <v-dialog v-model="dialog.args_spec"  max-width="70vw" >
        <v-sheet rounded="sm">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="12">
                        <h3>Filter and stream times</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <time-interval-selector @interval="(e)=>{args_spec.time_stream = -e}"></time-interval-selector>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Stream Time Seconds" v-model="args_spec.time_stream" outlined dense type="number" ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4">
                        <time-display :time="args_spec.time_stream" mode="timedelta"></time-display>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <time-interval-selector @interval="(e)=>{args_spec.time_conditions = -e}"></time-interval-selector>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Filter Time Seconds" v-model="args_spec.time_conditions" outlined dense  type="number" ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="4">
                        <time-display :time="args_spec.time_conditions" mode="timedelta"></time-display>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="12">
                        <v-btn class="ma-0"  elevation="2" x-small  style="float:right;" light fab @click="show.time_set_explain = ! show.time_set_explain" >
                            <v-icon small class="ma-0">mdi-help</v-icon>
                        </v-btn>
                        <div v-if="show.time_set_explain">
                            <ul>
                                <li> 
                                    Stream Time is the time that the input pair stream ends. The other settings are set by clicking on the input stream. 
                                </li>
                                <li>
                                    Filter time is when the conditions are being evaluated. 
                                </li>
                                <li>
                                    time seconds, if positive, is the epoch time. ( universal computer time, seconds since 1970 ) if negative, its the seconds from now in the past. so -300 is 5 mins ago.
                                </li>
                            </ul>

                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="12">
                        <v-btn class="ma-0"  elevation="2" small light block @click="prop_args_spec = {...args_spec}; dialog.args_spec = false" >Set and calculate</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-dialog>

    </div>
</template>
<script>
// Begin Component Imports
import IntelPairListDynamicConditions from '@/components/dynamic_lists/intel-pair-list-dynamic-conditions.vue'
import AppPairInputStreams from '@/components/app-pair-input-streams.vue'
import TimeDisplay from '@/components/time-display.vue'
import IntelPairList from '@/components/static_lists/intel-pair-list.vue'
import TimeIntervalSelector from '@/components/time-interval-selector.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'dynamic-list-now-listing-settings',
    components:{
        IntelPairListDynamicConditions,
        AppPairInputStreams,
        IntelPairList,
        TimeDisplay,
        TimeIntervalSelector,
    },
    emits:[
    ],
    data(){
        return {
            info:undefined,
            disp_list_id:0,
            args_spec : {
                time_stream:0,
                time_conditions:0,
            },
            dialog:{
                args_spec:false,
                input_pair_streams:false,
            },
            show:{
                time_set_explain:false,
            },
            prop_args_spec:{
                time_stream:0,
                time_conditions:0,
            }

        }
    },
    props: { 
        input_stream:{type:String,default:''},
        listId:{type:Number,default:Number},
        input_stream_args:{type:Object,default:Object},
    },
    mounted(){
        this.disp_list_id = this.listId
        this.get_dl_info()
    },
    methods:{
        ...mapActions(['token_list_actions','model_method']),
        get_dl_info(){
            this.model_method({method:'detailed_get',obj_id:this.listId, model:'PairListDynamic'}).then((r)=>{
                this.info = r.data
            })
        },
        set_input_stream_dl(ips){
            console.log("setting new input pair stream", ips);
            this.dialog.input_pair_streams = false
            this.info.input_stream_fnc_name = ips.exe_fnc
            this.info.input_stream_args_now.dt = ips.dt

            this.update_dl_info({
                input_stream_fnc_name:ips.exe_fnc,
                input_stream_args_now: this.info.input_stream_args_now })
        },
        update_dl_info(data){
            this.disp_list_id = 0
            this.token_list_actions({qs:{action:'update_list_info'},data:{
                list_id:this.listId, list_type:'tokens.dynamic', list_info:data}}).then((r)=>{
                    this.disp_list_id = this.listId
                })
        },
        reload_listings(){
            this.disp_list_id = 0 
            setTimeout(()=>{this.disp_list_id = this.listId}, 300)
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        listId(nv,ov){
            this.disp_list_id  = 0
            setTimeout(()=>{
                this.disp_list_id = this.listId
                this.get_dl_info()
            }, 100)
        }
    },
}
</script>
<style scoped lang="scss">
</style>