<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="12">
                    <h3 v-if="create_mode"> Create new condition </h3>
                    <h3 v-else>  update condition  [{{condition.name}}] </h3>
                </v-col>
                <v-col cols="12" md="12" v-if="create_mode" >
                    <v-select v-model="selected_domain" :items="['pair','trade','oracles.eth','oracles.economic','oracles.general','oracles.telegram','oracles.fiat']" label="selected_domain" dense rounded solo hide-details >
                        <template v-slot:selection="{ item, index }">
                            <div style="text-transform:capitalize"> {{item}} </div>
                            </template>
                        <template v-slot:item="{ item, index }">
                            <div style="text-transform:capitalize"> {{item}} </div>
                        </template>
                    </v-select>
                    <selector-metric @selected="select_new_metric" :domain="selected_domain" ></selector-metric>

                </v-col>
                <v-col cols="12" md="12" v-if="condition">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="12">
                                    <h4>Condition specification</h4>
                                    <dev-comment> (todo: remove when not in use) </dev-comment>
                                    <!-- {{condition.args_spec}} -->
                                    
                                    <v-container fluid>
                                        <v-row v-for="ags in condition.condition_option.args_spec" :key="ags.name">
                                            <v-col cols="12" md="12">
                                                <v-text-field 
                                                    :label="ags.name" 
                                                    :type="'text'"  
                                                    :hint="ags.example" 
                                                    v-model="ags.value" 
                                                    @change="set_arg_spec(ags)"
                                                    outlined 
                                                    dense ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                            </v-col>
                            <v-col cols="12" md="12">
                                <h4>Evaluation Arguments </h4>
                                <v-container fluid>
                                    <!-- {{condition.condition_option}} -->
                                    <v-row  v-for="arg in condition.condition_option.args_eval" :key="arg.name" >
                                        <!-- {{arg}} -->
                                        <v-col sm="12" md="4">
                                            <v-checkbox color="white" 
                                                v-model="arg.enabled"  
                                                @click="toggle_arg_eval_on_off(arg)"
                                                @change="(e)=>{ if(e){ arg.value = `` }else{ arg.value = null}}" 
                                                :label="arg.name" >
                                            </v-checkbox>
                                           
                                        </v-col>
                                        <!-- <v-col sm="12" md="3">
                                            <div style="text-transform:uppercase">
                                                {{arg.name}}
                                            </div>
                                        </v-col> -->
                                        <v-col cols="12" md="4" >
                                            <div v-if="arg.enabled">
                                                <v-select 
                                                v-model="arg.operator" 
                                                :items="operators" 
                                                label="eval" dense 
                                                rounded 
                                                solo 
                                                @change="set_arg_eval(arg)"
                                                hide-details ></v-select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="4" >
                                            <div v-if="arg.enabled">
                                                <v-select v-if="arg.type == 'bool' " 
                                                    v-model="arg.value" 
                                                    :items="[true, false]" 
                                                    label="arg.val" 
                                                    dense 
                                                    rounded 
                                                    solo 
                                                    @change="set_arg_eval(arg)"
                                                    hide-details ></v-select>

                                                <v-text-field  v-else
                                                    :type="'text'" 
                                                    :placeholder="decide_placeholder(arg.example)"
                                                    :label="arg.name"
                                                    color="white"
                                                    rounded
                                                    @change="set_arg_eval(arg)"
                                                    v-model="arg.value" outlined dense ></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                    </v-row>
                
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                    <h2> 
                    <span v-if="condition.fnc_name">  {{condition.fnc_name.replaceAll('metric_','')}} </span> </h2>
                    <v-sheet  class="ma-1"> </v-sheet>
                    <v-sheet class="ma-1"> </v-sheet>
                    
                </v-col>
                <v-col sm="12" md="12">
                        <v-btn v-if="action_button_text.length" class="ma-1" elevation="2" color="default" small block @click="do_condition_crud"> {{action_button_text}} </v-btn>
                            <v-btn v-if="!create_mode" color="red darken-3" x-small fab style="float:right;" @click="do_delete" ><v-icon class="ma-0">
                                mdi-trash-can
                            </v-icon>
                        </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <!-- <pre>
            {{ args_enabled }}
        </pre>
        -->
        <!-- <pre> {{condition}}  </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import DevComment from '@/components/dev-comment.vue'
import SelectorMetric from '@/components/selector-pair-metric.vue'
export default {
    name: 'builder-pair-condition',
    components:{
        SelectorMetric,
        DevComment
    },
    emits:[
        'new_condition'
    ],
    data(){return {
        selected_metric:undefined,
        operators:['is','less than','more than','is not'],
        build_metric:{},
        selected_domain:'pair',
        eval:'=',
        data:undefined,
        condition: undefined,
        create_mode:true,
        args_enabled:{},
        action_button_text:'Save',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //list:{type:Array,default:Array},

        conditionId:{type:Number,default:0},
        parentDynamicListPairId:{type:Number,default:0},
        
    },
    mounted(){
        if(this.conditionId !=0 ){ this.get_condition()}
    
    },
    methods:{
        ...mapActions(['strats_crud_condition','model_method']),
        decide_type(typen){
            if (typen ==  undefined){ return 'text'}
            if (typen == 'int'){ return 'number'}
            if (typen == '_empty'){ return 'text'}
            return 'text'
        },
        decide_placeholder(example){
            if(example == undefined){ return ''}
            return example.split('=')[1]
        },
        do_condition_crud(){

            this.strats_crud_condition({ 
                            action: this.create_mode ? 'create': 'update',
                            'condition': this.condition,
                            'pair_list_dynamic_id':this.parentDynamicListPairId,  
                            }).then(r=>{
                console.log(r.data);
                this.$emit('new_condition')
                this.reset()
                })
        },
        toggle_arg_eval_on_off(arg_eval_condition_option){
            if(this.arg_eval_exists(arg_eval_condition_option)){
                if (!arg_eval_condition_option.enabled){
                    this.condition.args_eval = this.condition.args_eval.filter(ae=> ae.name != arg_eval_condition_option.name)
                }
            } else {
                this.condition.args_eval.push(arg_eval_condition_option)
            }
        },
        arg_eval_exists(arg){
            const existing_arg_evals = this.condition.args_eval.map(ae =>ae.name)
            return existing_arg_evals.includes(arg.name)
            
        },
        set_arg_eval(condition_arg_eval){
            for (let i = 0; i < this.condition.args_eval.length; i++) {
                if(condition_arg_eval.name == this.condition.args_eval[i].name){
                    this.condition.args_eval[i].value =  condition_arg_eval.value;
                    this.condition.args_eval[i].operator =  condition_arg_eval.operator;
                    return 

                }
                
            }
        },
        set_arg_spec(ags){
            this.condition.args_spec[ags.name] = ags.value
        },
        get_condition(){
            this.model_method({model:'StrategyCondition',method:'obj_get', obj_id:this.conditionId}).then(r=>{
                this.condition = r.data
                this.create_mode = false
                this.args_enabled_populate(this.condition.args_eval, false)
                this.action_button_text = 'update and save'
            })

        },
        reset(){
            this.condition =  undefined
            this.selected_metric = undefined
            this.data = undefined
            this.create_mode = true

        },
        do_delete(){
            if(confirm("are you sure you want to delete this ?")){
                this.strats_crud_condition({ 
                            action: 'delete',
                            'condition': this.condition,
                            
                            }).then(r=>{
                console.log(r.data);
                this.$emit('new_condition')
                this.reset()
                })
            }
        },
        select_new_metric(m){
            console.log("New metric selected", m);
            this.condition = {
                    "pair_list_dynamic_id": this.parentDynamicListPairId,
                    "name": m.fnc_name,
                    "args_spec": m.args_spec,
                    "args_eval": [],
                    'condition_option': m
            }
            // this.selected_metric = m
            // this.condition = { id:this.conditionId, ...this.selected_metric }
            // this.args_enabled_populate(this.condition.args_eval, true)
            // this.action_button_text = 'create and save'
        },
        args_enabled_populate(args_eval,is_new){
            for (let i = 0; i < args_eval.length; i++) { 
                    var arg = args_eval[i]
                    if(is_new){
                        this.args_enabled[arg.name] = false
                    }else{
                        this.args_enabled[arg.name] = arg.value !== null; 
                    }
                }
        }
    },
    computed:{
        ...mapState([]),

    },
    watch:{
        conditionId(newV,oldV){
            if(this.conditionId !=0 ){ this.get_condition()}
            this.condition = undefined
            this.create_mode = true
        },

    },
}
</script>
<style scoped lang="scss">
</style>