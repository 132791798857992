<template>
    <div>
        <div v-if="pair_data">
            
            <div>
                <v-container fluid>
                    <v-row>
                        <v-col sm="12" md="12" v-if="pair_data.all_pair_count" style="display:flex; justify-content:center;">
                            {{pair_data.listings.length}} of {{pair_data.all_pair_count}} pairs filtered ({{(100*pair_data.listings.length/ pair_data.all_pair_count).toFixed(1)}} % )
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-btn class="ma-1"  elevation="2" small light  @click="remove_selected_pairs_from_list" v-if="selected_ids.length" > remove selected</v-btn>
                            <v-btn class="ma-1"  elevation="2" small light  @click="dialog.add_to_portfolio= !dialog.add_to_portfolio" v-if="selected_ids.length" > Add {{selected_ids.length}} selected to list</v-btn>
                            <v-btn class="ma-1"  elevation="2" small  @click="dialog.metrics= !dialog.metrics" > <v-icon small class="ma-1">mdi-chart-scatter-plot</v-icon> </v-btn>
                            <v-btn class="ma-1"  elevation="2" small  @click="dialog.static_list_rois = !dialog.static_list_rois" v-if="list_type == 'tokens.static' "> <v-icon class="ma-0">mdi-chart-timeline</v-icon></v-btn>
                            <v-btn class="ma-1"  elevation="2" small  @click="dialog.rejected_pairs= !dialog.rejected_pairs" v-if="list_type == 'tokens.dynamic' "><v-icon class="ma-0">mdi-filter-variant-remove</v-icon></v-btn>
                            <!-- <pre> {{pair_data}} </pre> -->
                            <!-- 
                                <v-btn class="ma-1"  elevation="2" small light  @click="0" > Check correlations</v-btn>
                                <v-btn class="ma-1"  elevation="2" small light  @click="0" > ML</v-btn> 
                            -->
                        </v-col>
                        <v-col cols="12" md="12">
                    
                            <div style="width:100%;display:flex;justify-content:center" v-if="is_loading" class="ma-1">
                                <v-progress-circular indeterminate color="white" ></v-progress-circular>
                            </div>
                            <div v-else>
                      
                                <table>
                                    <tr>
                                        <th><v-icon class="ma-0" small >mdi-plus</v-icon></th>
                                        <th>Age </th>
                                        <th>pair</th>
                                        <th>price</th>
                                        <th>liq</th>
                                        <th>now x</th>
                                        <th>ath x</th>
                                        <th v-if="list_type =='tokens.dynamic'" >
                                            <v-btn class="ma-0"  elevation="2" small block @click="show.eval_metrics= !show.eval_metrics" >
                                                <v-icon v-if="!show.eval_metrics" class="ma-0">mdi-dots-horizontal</v-icon>
                                                <div v-else > eval metrics </div>
                                            </v-btn>
                                        </th>
                                    </tr>
                                    <tr v-for="l in pair_data.listings" :key="l.id"  :class="{'selected-pair':selected_ids.includes(l.pair.id)}">
                                        <td @click="toggle_selected_ids(l.pair.id)" ><v-icon class="ma-0" small color="grey" >mdi-plus</v-icon></td>
                                        <td>
                                            <time-display :time="l.pair.created_at" mode="timesince" ></time-display>
                                        </td>
                                        <td> <pair-link-to-page :pair=l.pair ></pair-link-to-page> </td>
                                        <td> <v-sparkline  :smooth="16" :gradient="['#1feaea', '#ffd200','#f72007'  ]" :line-width="3" :value="l.pair.price.prices" auto-draw stroke-linecap="round" ></v-sparkline> </td>
                                        <td> <base-amount :amount="l.pair.liquidity"></base-amount></td>
                                        <td> {{l.pair.price.curr_x}}  <sub>x</sub> </td>
                                        <td> {{l.pair.price.ath_x}}   <sub>x</sub> </td>
                                        <td v-if="list_type =='tokens.dynamic'">
                                            <table v-if="show.eval_metrics">
                                                <tbody v-for="(metric_sheet,i) in pair_data.calc_metrics[l.pair.id]" :key="i" >
                                                    
                                                    <tr v-for="metric in metric_sheet" :key="metric.metric_name" >
                                                        <th> {{metric.metric_name}}</th>
                                                        <td>
                                                            <table>
                                                                <tr v-for="(metric_element_val,metric_element_name) in metric.metrics" :key="metric_element_name">
                                                                    <th > <small>{{metric_element_name}}</small></th>
                                                                    <td> {{metric_element_val}} </td>
                                                                </tr>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="color:grey">  </td>
                                        <td  style="color:grey"> {{avg_now_x.toFixed(2)}} <sub>x</sub> </td>
                                        <td  style="color:grey"> {{avg_ath_x.toFixed(2)}} <sub>x</sub> </td>
                                        <td v-if="list_type =='tokens.dynamic'">
                                            
                                        </td>
                                    </tr>
                                </table>



                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
                      
            <v-dialog v-model="dialog.metrics"  max-width="70vw" >
                <v-sheet rounded="sm" class="pa-1">
                    <intel-token-metrics-scatter :pairs="pair_data.listings.map(x=> x.pair)"></intel-token-metrics-scatter>
                </v-sheet>
            </v-dialog>
           
            <v-dialog v-model="dialog.add_to_portfolio"  max-width="70vw" >
                <v-sheet rounded="sm">
                    <my-list-add-pair @added="dialog.add_to_portfolio =  false" :selected_pairs="pair_data.listings.filter((p)=>{return selected_ids.includes(p.pair.id)}).map((p)=>{return {name:p.pair.name, symbol:p.pair.symbol, id:p.pair.id} })" ></my-list-add-pair>
                </v-sheet>
            </v-dialog>

            <v-dialog v-model="dialog.static_list_rois" >
                <v-sheet rounded="sm">
                    <my-list-static-active-listings :listId="list_id"></my-list-static-active-listings>
                   
                </v-sheet>
            </v-dialog>

            <v-dialog v-model="dialog.rejected_pairs"  max-width="80vw" >
                <v-sheet rounded="sm">
                    <h3>rejected pairs</h3>
                    <table v-if="list_type =='tokens.dynamic'">
                        <tr v-for="rpi in pair_data.rejected_pair_ids" :key="rpi">
                            <td>{{rpi}}</td>
                            <td>
                                <div v-for="(metric_sheet,i) in pair_data.calc_metrics[rpi]" :key="i">
                                        <div v-for="metric in metric_sheet" :key="metric.metric_name"> 
                                        <div :style="`color:${metric.passed ? 'green' :'red'}`" v-for="(metric_element_val,metric_element_name) in metric.metrics" :key="metric_element_name" >
                                        {{metric_element_name}} : {{metric_element_val}} 
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-sheet>

            </v-dialog>

        </div>
        <div v-else  style="width:100%;display:flex;justify-content:center" class="ma-1">
            <v-progress-circular indeterminate color="white" ></v-progress-circular>
        </div>
    </div>
</template>
<script>
// Begin Component Imports
import BaseAmount from '@/components/base-amount.vue';
import PairLinkToPage from '@/components/pair-link-to-page.vue';
import MyListAddPair from '@/components/static_lists/my-list-add-pair.vue';
import MyListStaticActiveListings from '@/components/static_lists/my-list-static-active-listings.vue';
import IntelTokenMetricsScatter from '../intel-token-metrics-scatter.vue';
import TimeDisplay from '../time-display.vue';
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TimeIntervalSelector from '../time-interval-selector.vue';
export default {
    name: 'intel-pair-list',
    components:{
        MyListAddPair,
        PairLinkToPage,
        BaseAmount,
        IntelTokenMetricsScatter,
        TimeDisplay,
        MyListStaticActiveListings,
        TimeIntervalSelector,
    },
    emits:[

    ],
    data(){return {
        selected_ids:[],
        pair_data: undefined,
        dialog:{
            metrics:false,
            add_to_portfolio:false,
            static_list_rois:false,
            rejected_pairs:false,
        },
        show:{
            eval_metrics:false,
        },
        is_loading: true,
        }
    },
    props: { 
        list_id :{type:Number,default:Number},
        list_type:{type:String, default:'tokens.static'},
        dl_args_spec:{type:Object, default:Object}
    },
    mounted(){
        this.do_get_standard_listings()

    },
    methods:{
        ...mapActions(['token_list_actions']),
        do_get_standard_listings(){
            let query_data  = {
                list_id:this.list_id, 
                list_type:this.list_type,
                ...this.dl_args_spec
                }
            this.is_loading = true
            this.token_list_actions({qs:{action:'get_listings'},data:query_data}).then((r)=>{ 
                        this.is_loading = false
                        this.pair_data = r.data
                        this.selected_ids = []
                        })
        },
        toggle_selected_ids(pair_id){
            if( this.selected_ids.includes(pair_id)){this.selected_ids = this.selected_ids.filter(x=> x != pair_id)}
            else { this.selected_ids.push(pair_id)}
        },
        remove_selected_pairs_from_list(){
            if(confirm("Are you sure you want to remove these pairs from this list?")){
                this.is_loading = true
                this.token_list_actions({qs:{action:'remove_pairs_from_list'},data:{list_id:this.list_id, pair_ids:this.selected_ids, list_type:this.list_type}}).then((r)=>{ 
                    this.is_loading = false
                    this.pair_data = undefined;
                    this.do_get_standard_listings()
                })

            }
            
        }
    },
    computed:{
        ...mapState([]),
        avg_ath_x(){
            if (this.pair_data == undefined){return 0}
            const rois = this.pair_data.listings.map((p)=>{return p.pair.price.ath_x})
            const sum = rois.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const average = sum / rois.length;
            return average

        },
        avg_now_x(){
            const rois = this.pair_data.listings.map((p)=>{return p.pair.price.curr_x})
            const sum = rois.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const average = sum / rois.length;
            return average

        },
        filter_time(){
            return new Date() - (this.filter_time_offset*1000)
        }
    },
    watch:{
        list_id(nv,ov){
            this.do_get_standard_listings()
            this.selected_ids = []
        },
        dl_args_spec(nv,ov){
            this.do_get_standard_listings()
            this.selected_ids = []
        }
    },
}
</script>
<style scoped lang="scss">


.selected-pair{
    background:rgba(0, 183, 255, 0.336);
}
td{
    vertical-align: top;
    text-align: center;

}

</style>