<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>Active Listings </h3>
                    
                </v-col>
                <v-col cols="12" md="12">
                    
                    <table>
                        <tr>
                            <th>pair</th>
                            <th>entry</th>
                            <th>leave</th>
                            <th>ROI</th>
                            <th></th>

                        </tr>
                        <tr v-for="p in list_data.pairs" :key="p.id">
                            <td> <pair-link-to-page :pair="p.pair" ></pair-link-to-page></td>
                            <td> <time-display :time="p.entry_time"></time-display></td>
                            <td> <time-display :time="p.leave_time"></time-display> </td>
                            <td :style="`color:${decide_roi_color(p.roi)}`">{{p.roi.toFixed(2)}} <sub>x</sub> </td>
                            <td>
                                <v-btn v-if="p.leave_time == 0 " class="ma-0"  elevation="2" small block @click="0" > leave </v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th :style="`color:${decide_roi_color(avg_roi)}`" >{{avg_roi.toFixed(3)}} <sub>x</sub> </th>
                            <td></td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import PairLinkToPage from '../pair-link-to-page.vue'
import TimeDisplay from '../time-display.vue'
export default {
    name: 'my-list-static-active-listings',
    components:{
        PairLinkToPage,
        TimeDisplay
    },
    emits:[
 
        TimeDisplay   ],
    data(){return {
        mode:'active',
        list_data:undefined,
        }
    },
    props: { 
        listId:{type:Number,default:Number},
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
    },
    mounted(){
        this.do_get_data()
    
    },
    methods:{
        ...mapActions(['static_pair_list_actions']),
        do_get_data(){
                this.static_pair_list_actions({data:{list_id:this.listId, status:this.mode},qs:{action:'get_list_data'}}).then( r=> {
                   
                 this.list_data = r.data
                    
                })
        },
        decide_roi_color(roi){
            if(roi < 0.01){return 'red'}
            if(roi < 1){return '#ffb770'}
            return '#41f141'

        }
    },
    computed:{
        ...mapState([]),
        avg_roi(){
            if (this.list_data == undefined){return 0}
            const rois = this.list_data.pairs.map((p)=>{return p.roi})
            const sum = rois.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const average = sum / rois.length;
            return average
        }
    },
    watch:{
        listId(){this.do_get_data()}
    },
}
</script>
<style scoped lang="scss">

</style>