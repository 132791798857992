<template>
    <div>
        <div v-if="conditions">
            <v-menu v-model="contextMenuOpen" :position-x="contextMenuX" :position-y="contextMenuY">
                <v-list>
                    <v-list-item @click="dialog.edit_condition = true"><v-icon class="mr-1">mdi-pencil</v-icon> Edit</v-list-item>
                    <!-- <v-list-item @click="0"><v-icon class="mr-1">mdi-delete</v-icon> Remove</v-list-item> -->
                    <v-list-item @click="dialog.add_condition = true" > <v-icon class="mr-1">mdi-plus</v-icon> Add Condition</v-list-item>
                </v-list>
            </v-menu>


            <div class="trigger_action sheet">
                <div v-if="conditions.length == 0"> 
                    <v-btn class="ma-0"  elevation="2" small block @click="dialog.add_condition = true" >
                        <v-icon class="mr-1">mdi-plus</v-icon>
                        Add First Condition
                    </v-btn>
                </div>
            
                <div  v-for="(condition,j) in conditions"  :key="condition.id"  @contextmenu.prevent="selected.condition = condition; showContextMenu($event)"  >
                    <!-- {{ condition }} -->
                    <div class="trigger_action_condition">
                        <div class="trigger_action_condition_title"> 
                            <sup>   <span v-if="j">and</span>    if  </sup> {{condition.condition_option.fnc_name.replaceAll('metric_','').replaceAll('_',' ')}} 
                            <br>
                            <!-- {{ condition.id }} -->
                            <small style="text-align:center; color:gray; text-transform:capitalize;" v-for="(k,v) in condition.condition_option.args_spec" :key="k.name">
                                {{k.name.replaceAll('_',' ')}}  = {{condition.spec_value[k.name]}}
                                <br>

                            </small>
                            <v-icon  v-if="edit_mode" class="ma-0" small  @click="selected.condition = condition.id; dialog.edit_condition= !dialog.edit_condition"> mdi-file-edit </v-icon> 
                        </div>
                        
                        <div> 
                            <table v-for="eval_arg in condition.eval_value" :key="`${condition.id}${eval_arg.name}`">
                                <!-- {{ condition.id }} -->
                                <tr v-if="eval_arg.value != null" >
                                    <td> {{eval_arg.name.replaceAll('_',' ')}} </td>
                                    <td style="padding-right:5px; padding-left:5px;"> <sub > {{eval_arg.operator}} </sub> </td>
                                    <td> <span > {{eval_arg.value}} </span> </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
                <div v-if="edit_mode" > 
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  v-bind="attrs" v-on="on" class="ma-2" elevation="2" color="white" light x-small  outlined  
                            @click="selected.condition=0; dialog.add_condition= !dialog.add_condition" > 
                                <v-icon small class="ma-0" >mdi-layers-plus</v-icon>
                            </v-btn>
                        </template>
                        <span >+ New Condition</span>
                    </v-tooltip>
                    <v-btn class="ma-0"  elevation="2" x-small outlined  @click="edit_mode=!edit_mode" color="gray"  ><v-icon  small class="ma-0">mdi-layers-edit</v-icon></v-btn>            
                </div>
            </div>

            <v-dialog v-model="dialog.add_condition"  max-width="60vw" >
                <v-sheet rounded="sm">
                    <builder-pair-condition  :conditionId="0" :parentDynamicListPairId="pair_dynamic_list_id" @new_condition="dialog.add_condition=false; refresh_data()" ></builder-pair-condition>
                </v-sheet>
            </v-dialog>
            <v-dialog v-if="selected.condition" v-model="dialog.edit_condition"  max-width="60vw" >
                <v-sheet rounded="sm">
                    <builder-pair-condition  :conditionId="selected.condition.id" :parentDynamicListPairId="pair_dynamic_list_id" @new_condition="selected.condition = false; refresh_data()" ></builder-pair-condition>
                </v-sheet>
            </v-dialog>


        </div>
        <div style="display:flex;justify-content:center" v-else class="ma-2">
             <v-progress-circular indeterminate color="white" ></v-progress-circular>
        </div>
        
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BuilderPairCondition from '@/components/builder-pair-condition.vue'
export default {
    name: 'intel-pair-list-dynamic-conditions',
    components:{
        BuilderPairCondition
    },
    emits:[
        'refresh'
    ],
    data(){return {
        edit_mode :false,
        dialog:{add_condition:false, edit_condition:false},
        selected:{condition:undefined},
        conditions: undefined,
        contextMenuX:0,
        contextMenuY:0,
        contextMenuOpen:false

        }
    },
    props: { 
        pair_dynamic_list_id:{type:Number,default:0},
        //data:{type:Object,default:Object},
        // conditions:{type:Array,default:Array},
        set_edit_mode:{type:Boolean, default:false},
    },
    mounted(){
        this.do_get_dynamic_list_conditions()
    
    },
    methods:{
        ...mapActions(['pair_list_dynamic_actions']),
        do_get_dynamic_list_conditions(){
            this.conditions = undefined
            this.pair_list_dynamic_actions({qs:{action:'get_conditions'},data:{pair_list_dynamic_id:this.pair_dynamic_list_id}}).then(r=>{
                this.conditions = r.data
            })

        },
        showContextMenu(event, ) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.contextMenuOpen = true             
        },
        refresh_data(){
            this.do_get_dynamic_list_conditions()
            this.$emit('refresh')
        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        set_edit_mode(nv,ov){
            this.edit_mode = nv
        },
        pair_dynamic_list_id(nv,ov){
            this.do_get_dynamic_list_conditions()
        }
    },
}
</script>
<style scoped lang="scss">

      .trigger_action_condition{
            margin-bottom:1em;
            background: linear-gradient( 45deg , #ffffff10, #ffffff10);
            border-radius: 0px 10px 10px 0px;
            flex-direction: column;
            display:flex;
            align-items: center;
            justify-content: space-between;
            div{
                margin:2px;
                height:100%;
                // background-color: rgba(248, 248, 248, 0.05);
                flex:1;
                white-space: pre-line;
                table{
                    td{
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
                
            }
            .trigger_action_condition_title{
                background-color: transparent;
                text-transform: uppercase;
                font-size: 0.9em;
                text-align: center;
            }
     
        }

</style>