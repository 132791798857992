<template>
    <div>

        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>Input Streams</h3>
                    
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete 
                    v-model="selected_input_stream_model" 
                    :items="all_input_streams" 
                    item-title="name"  item-text="name" return-object
                    label="Select an Input Stream"  >
                        <template v-slot:selection="data">
                                {{ data.item.name }}
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>{{data.item.name}}</v-list-item-content>
                        </template>
                    </v-autocomplete>
          
                </v-col>
                <v-col sm="12" md="12" v-if="selected_ips" >
                    <h3>set to {{selected_ips.name}}</h3>
                </v-col>
                <v-col sm="12" md="4">
                    <!-- <v-btn v-for="ips in all_input_streams" :key="ips.name" class="ma-1"  elevation="2" small block @click="selected_ips = {...ips}" > {{ips.name}} </v-btn> -->
                    
                   
                    <div v-if="selected_ips">
                        <v-container fluid>
                            <v-row>
                 
                                <v-col cols="12" md="12" v-for="(arg,i) in selected_ips.args_spec" :key="arg.name">
                                    <div >
                                        <!-- {{i}} -->
                                        <div v-if="arg.name.includes('interval') ">
                                            <h5>{{arg.name}}</h5>
                                            <time-interval-selector :startTimeSeconds="arg.value" @interval="(inv)=>{selected_ips.args_spec[i].value = inv}"></time-interval-selector>
                                            <time-display style="float:right;" :time="arg.value" mode="timedelta"></time-display>
                                        </div>
                                        <div v-else-if="arg.name.includes('time') ">
                                            <h5>{{arg.name}}</h5>
                                            <time-interval-selector label="Interval Ago" :startTimeSeconds="arg.value" @interval="(inv)=>{selected_ips.args_spec[i].value = -inv}"></time-interval-selector>
                                            <time-display style="float:right;" :time="arg.value" mode="timedelta"></time-display>
                                        </div>
                                        <v-text-field v-else  :label="arg.name" v-model="arg.value" outlined dense hide-details :hint="arg.example"></v-text-field>
                                    </div>
                                    
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-btn class="mt-1"  elevation="2" small light  block @click="do_pair_input_stream_test" >test stream</v-btn>
                                    <v-btn v-if="dl_id" class="mt-1"  elevation="2" small color="red" block @click="do_pair_input_stream_test_save" > {{msg.save}}</v-btn>
                                    
                                </v-col>

                            </v-row>
                        </v-container>
                    </div>
                </v-col>
                <v-col sm="12" md="8">
                    <div v-if="is_loading">
                        <v-progress-circular indeterminate color="white" ></v-progress-circular>
                    </div>
                    <div v-else>
                        <div v-if="tst_cnt != undefined"> {{tst_cnt}} Objects Found </div>
                        <div style="display:flex; flex-wrap:wrap;">
                            <div v-for="p in pairs" :key="p.id" >
                                <pair-link-to-page :pair="p" ></pair-link-to-page>
                            </div>
                        </div>
                    </div>
                    {{selected_ips}}
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import pairLinkToPage from '@/components/pair-link-to-page.vue'
import TimeIntervalSelector from '@/components/time-interval-selector.vue'
import TimeDisplay from '@/components/time-display.vue'

// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'app-pair-input-streams',
    components:{
        pairLinkToPage,
        TimeIntervalSelector,
        TimeDisplay
    },
    emits:[
        'saved'
    ],
    data(){return {
        all_input_streams:[ ],
        selected_ips:undefined,
        selected_input_stream_model:undefined,
        pairs:[],
        is_loading:false,
        tst_cnt:undefined,
        msg:{
            save:'set stream',
        }
        }
    },
    props: { 
        filter:{type:String,default:''},
        saved_input_stream:{type:Object,default:Object},
        dl_id:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
        
        this.list_all_input_streams({qs:{},data:{}}).then((r)=>{
            this.all_input_streams = r.data            
            let ips_args_spec =  []
            for (let arg_name in this.saved_input_stream.args) {
                ips_args_spec.push({
                    name:arg_name,
                    value:this.saved_input_stream.args[arg_name],
                    type: 'text'
                })
            }
            this.selected_ips = {
                name: this.saved_input_stream.name,
                args_spec:ips_args_spec
                }
            })
    
    },
    methods:{
        ...mapActions(['pair_input_stream_test','list_all_input_streams']),

        do_pair_input_stream_test(){
            this.is_loading = true
            this.pair_input_stream_test({qs:{test_get:'1'},data:this.selected_ips}).then((r)=>{
                this.pairs = r.data.test_pairs
                this.tst_cnt = r.data.test_pairs.length
                this.is_loading = false
            })
        },
        do_pair_input_stream_test_save(){
            this.msg.save = `saving to ${this.dl_id}`
            this.pair_input_stream_test({qs:{filter_id:this.dl_id},data:this.selected_ips}).then((r)=>{
                this.is_loading = false
                this.msg.save = 'Done.'
                this.$emit('saved')
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        selected_input_stream_model(nv,ov){
            this.selected_ips = this.selected_input_stream_model

        }

    },
}
</script>
<style scoped lang="scss">
</style>