<template>
    <div>
        <div class="my-comment">
            <slot> </slot> 
        </div>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'dev-comment',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.my-comment{
    font-size: 0.5;
    color:gray;    
}

</style>