<template>
    <div @contextmenu.prevent="showContextMenu($event)" >
        <v-menu v-model="contextMenuOpen" :position-x="contextMenuX" :position-y="contextMenuY">
                <v-list dense>
                    <v-list-item dense @click="dialog.pair_listing= !dialog.pair_listing">View Pair Stream</v-list-item>
                    <v-list-item dense @click="handleMenuItemClick('Option 2')">Edit</v-list-item>
                    <v-list-item dense @click="handleMenuItemClick('Option 2')">DL Info</v-list-item>
                </v-list>
        </v-menu>
        <div v-if="dl_info.input_stream_fnc_name != 'strategy'" >
            <v-btn class="ma-0"  elevation="2" small  block @click="dialog.pair_listing= !dialog.pair_listing" > 
                <v-icon class="ma-1" small>mdi-arrow-down</v-icon>
                    {{dl_info.input_stream_fnc_name.replaceAll('_',' ')}}
                    <div v-if="dl_info.input_stream_args_now" class="ml-1"> {{dl_info.input_stream_args_now.dt /60}} min </div>
                <v-icon class="ma-1" small>mdi-arrow-down</v-icon>
            </v-btn>
        </div>
        <div class="trigger_action sheet">
            <div  v-for="(condition,j) in dl_info.conditions"  :key="condition.id" >
                <!-- {{ condition }} --> 
                <div class="trigger_action_condition">
                    <div class="trigger_action_condition_title"> 
                        <sup>   <span v-if="j">and</span>    if  </sup> {{condition.condition_option.fnc_name.replaceAll('metric_','').replaceAll('_',' ')}} 
                        <br>
                        <!-- {{ condition.spec_value }} -->
                        <small style="text-align:center; color:gray; text-transform:capitalize;" v-for="(k,v) in condition.spec_value" :key="k.name">
                            {{v.replaceAll('_',' ')}}  = {{ k }}
                        </small>
                        <v-icon  v-if="edit_mode" class="ma-0" small  @click="selected.condition = condition.id; dialog.edit_condition= !dialog.edit_condition"> mdi-file-edit </v-icon> 
                    </div>
                    
                    <div> 
                        <table v-for="eval_arg in condition.eval_value" :key="`${condition.id}${eval_arg.name}`">
                            <!-- {{ condition.id }} -->
                            <tr v-if="eval_arg.value != null" >
                                <td> {{eval_arg.name.replaceAll('_',' ')}} </td>
                                <td style="padding-right:5px; padding-left:5px;"> <sub > {{eval_arg.operator}} </sub> </td>
                                <td> <span > {{eval_arg.value}} </span> </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="edit_mode" > 
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  v-bind="attrs" v-on="on" class="ma-2" elevation="2" color="white" light x-small  outlined  
                        @click="selected.condition=0; dialog.add_condition= !dialog.add_condition" > 
                            <v-icon small class="ma-0" >mdi-layers-plus</v-icon>
                        </v-btn>
                    </template>
                    <span >+ New Condition</span>
                </v-tooltip>
                <v-btn class="ma-0"  elevation="2" x-small outlined  @click="edit_mode=!edit_mode" color="gray"  ><v-icon  small class="ma-0">mdi-layers-edit</v-icon></v-btn>            
            </div>
            
        
        </div>

        <v-dialog v-model="dialog.pair_listing"  max-width="70vw" >
            <v-sheet rounded="sm">
                <dynamic-list-now-listing-settings :listId="dl_info.id" ></dynamic-list-now-listing-settings>
            </v-sheet>
        </v-dialog>

        <v-dialog v-model="dialog.add_condition"  max-width="60vw" >
            <v-sheet rounded="sm">
                <builder-pair-condition  :conditionId="0" :parentDynamicListPairId="dl_info.id" @new_condition="dialog.add_condition=false; $emit('refresh')" ></builder-pair-condition>
            </v-sheet>
        </v-dialog>

        <v-dialog v-if="selected.condition" v-model="dialog.edit_condition"  max-width="60vw" >
             <v-sheet rounded="sm">
                <builder-pair-condition  :conditionId="selected.condition" :parentDynamicListPairId="dl_info.id" @new_condition="selected.condition = false; $emit('refresh')" ></builder-pair-condition>
             </v-sheet>
        </v-dialog>



    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BuilderPairCondition from '@/components/builder-pair-condition.vue'
import DynamicListNowListingSettings from '@/components/dynamic_lists/dynamic-list-now-listing-settings.vue'
export default {
    name: 'intel-pair-list-dynamic-list-edit',
    components:{
        BuilderPairCondition,
        DynamicListNowListingSettings,
    },
    emits:[
        'refresh'
    ],
    data(){return {
            contextMenuOpen: false,
            contextMenuX: 0,
            contextMenuY: 0,
            selectedOption: null,


        edit_mode :false,
        dialog:{
            add_condition:false, 
            edit_condition:false,
            pair_listing:false,
            },
        selected:{condition:undefined}
        }
    },
    props: { 
      
        //data:{type:Object,default:Object},
        dl_info:{type:Object, default:Object},
        set_edit_mode:{type:Boolean, default:false},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),

        showContextMenu(event) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.contextMenuOpen = true;
        },
        handleMenuItemClick(option) {
            this.selectedOption = option;
            this.contextMenuOpen = false;
        },


    },
    computed:{
        ...mapState([]),
    },
    watch:{
        set_edit_mode(nv,ov){
            this.edit_mode = nv
        },
    },
}
</script>
<style scoped lang="scss">

      .trigger_action_condition{
            margin-bottom:1em;
            background: linear-gradient( 45deg , #ffffff10, #ffffff10);
            border-radius: 0px 10px 10px 0px;
            flex-direction: column;
            display:flex;
            align-items: center;
            justify-content: space-between;
            div{
                margin:2px;
                height:100%;
                // background-color: rgba(248, 248, 248, 0.05);
                flex:1;
                white-space: pre-line;
                table{
                    td{
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
                
            }
            .trigger_action_condition_title{
                background-color: transparent;
                text-transform: uppercase;
                font-size: 0.9em;
                text-align: center;
            }
     
        }

</style>